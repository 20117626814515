dmx.Component('bs5-theme-switch', {

  initialData: {
    theme: 'auto',
  },

  attributes: {
    icons: {
      type: String,
      default: 'bi',
      enum: ['bi', 'fa'],
    },

    manager: {
      type: String,
      default: '',
    }
  },

  events: {
    change: Event,
  },

  render: function(node) {
    this._icons = {
      bi: {
        auto: 'bi-circle-half',
        check: 'bi-check2',
        dark: 'bi-moon-stars-fill',
        light: 'bi-sun-fill',
      },
      fa: {
        auto: 'fa-adjust',
        check: 'fa-check',
        dark: 'fa-moon fa-moon-o',
        light: 'fa-sun fa-sun-o',
      },
    };

    this._prefix = node.id;

    const icons = this._icons[this.props.icons];
    const isNavItem = node.classList.contains('nav-item') || node.parentNode.classList.contains('nav-item');

    node.classList.add('dropdown');

    node.innerHTML = `
      <button class="btn ${isNavItem ? 'btn-link nav-link' : 'btn-primary'} py-2 dropdown-toggle d-flex align-items-center" id="${this._prefix}-theme" type="button" aria-expanded="false" data-bs-toggle="dropdown" aria-label="Toggle theme (auto)">
        <i class="${this.props.icons} ${icons.auto} ${this.props.icons == 'fa' ? 'my-1' : ''} theme-icon-active"></i>
        <span class="visually-hidden" id="${this._prefix}-theme-text">Toggle theme</span>
      </button>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="${this._prefix}-theme-text">
        <li>
          <button type="button" class="dropdown-item d-flex align-items-center" data-bs-theme-value="light" aria-pressed="false">
            <i class="${this.props.icons} ${icons.light} me-2 opacity-50"></i>
            Light
            <i class="${this.props.icons} ${icons.check} ms-auto d-none"></i>
          </button>
        </li>
        <li>
          <button type="button" class="dropdown-item d-flex align-items-center" data-bs-theme-value="dark" aria-pressed="false">
            <i class="${this.props.icons} ${icons.dark} me-2 opacity-50"></i>
            Dark
            <i class="${this.props.icons} ${icons.check} ms-auto d-none"></i>
          </button>
        </li>
        <li>
          <button type="button" class="dropdown-item d-flex align-items-center" data-bs-theme-value="auto" aria-pressed="true">
            <i class="${this.props.icons} ${icons.auto} me-2 opacity-50"></i>
            Auto
            <i class="${this.props.icons} ${icons.check} ms-auto d-none"></i>
          </button>
        </li>
      </ul>
    `;

    node.querySelectorAll('[data-bs-theme-value]').forEach(toggle => {
      toggle.addEventListener('click', () => {
        	this.set('theme', toggle.getAttribute('data-bs-theme-value'));
          this._setTheme(this.data.theme);
          node.querySelector(`#${this._prefix}-theme`).focus();
      });
    });

    this.update();
  },

  update: function(props) {
    const icons = this._icons[this.props.icons];

    this.set('theme', dmx.parse(`${this.props.manager}.theme`, this));

    this.$node.querySelectorAll('[data-bs-theme-value]').forEach(element => {
      if (element.getAttribute('data-bs-theme-value') === this.data.theme) {
        element.classList.add('active');
        element.setAttribute('aria-pressed', 'true');
        element.querySelector('.' + icons.check).classList.remove('d-none');
      } else {
        element.classList.remove('active');
        element.setAttribute('aria-pressed', 'false');
        element.querySelector('.' + icons.check).classList.add('d-none');
      }
    });

    const activeThemeIcon = this.$node.querySelector('.theme-icon-active');
    Object.values(icons).forEach(cls => activeThemeIcon.classList.remove.apply(activeThemeIcon.classList, cls.split(' ')));
    activeThemeIcon.classList.add.apply(activeThemeIcon.classList, icons[this.data.theme].split(' '));
    
    const themeSwitcher = this.$node.querySelector(`#${this._prefix}-theme`);
    themeSwitcher.setAttribute('aria-label', `Toggle theme (${this.data.theme})`);
  },

  _setTheme: function(theme) {
    if (this.props.manager) {
      dmx.parse(`${this.props.manager}.setTheme("${theme}")`, this);
    }
    this.dispatchEvent('change', {}, { theme });
  },

});