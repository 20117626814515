dmx.Component('bs5-theme', {
  initialData: {
    theme: 'auto',
  },

  attributes: {
    key: {
      type: String,
      default: 'theme',
    },

    theme: {
      type: String,
      default: 'auto',
      enum: ['auto', 'light', 'dark'],
    },
  },

  methods: {
    setTheme: function(theme) {
      this.props.theme = theme;
      this.update();
    },
  },

  render: function() {
    this._changeHandler = this._changeHandler.bind(this);
    this._mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this._mediaQuery.addEventListener('change', this._changeHandler);
    this.props.theme = this._getStoredTheme() || this.props.theme;
    this._update();
  },

  beforeDestroy: function() {
    this._mediaQuery.removeEventListener('change', this._changeHandler);
  },

  update: function() {
    this._setStoredTheme(this.props.theme);
    this._update();
  },
  
  _update: function() {
    if (this.props.theme === 'auto') {
      document.documentElement.setAttribute('data-bs-theme', this._mediaQuery.matches ? 'dark' : 'light');
    } else {
      document.documentElement.setAttribute('data-bs-theme', this.props.theme);
    }

    this.set('theme', this.props.theme);
  },

  _changeHandler: function(event) {
    this._update();
  },

  _getStoredTheme: function() {
    return localStorage.getItem(this.props.key);
  },

  _setStoredTheme: function(theme) {
    localStorage.setItem(this.props.key, theme);
  },

});